import React from "react";
import Event1 from "./components/event1/Event1";

const Events = () => {
  return (
    <div>
      <Event1 />
    </div>
  );
};
export default Events;
