import styles from "./style.module.css";
export default function SynthWave() {
  return (
    <>
      <div class={styles.main}>
        <section class={styles.movingGrid}>
          <div class={styles.horzMain}></div>
          <div class={styles.container}>
            <div class={styles.staticLines}>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
            </div>
            <div class={styles.movingLines}>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
            </div>
          </div>
        </section>
        {/* <img src={logo} class={styles.image}></img> */}
        <section class={styles.movingGrid2}>
          <div class={styles.horzMain}></div>
          <div class={styles.container}>
            <div class={styles.staticLines}>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
              <div class={styles.vert}></div>
            </div>
            <div class={styles.movingLines}>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
              <div class={styles.horz}></div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
