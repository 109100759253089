const p2021 = [
    "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813369/gallery_page/2021/1_Large_gcjv7a.jpg",
    "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813370/gallery_page/2021/2_Large_g4utoz.jpg",
    "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813373/gallery_page/2021/3_Large_jvxic3.jpg",
    "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813370/gallery_page/2021/4_Large_tbrz3p.jpg",
    "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813371/gallery_page/2021/5_Large_dbgxkv.jpg",
    "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813370/gallery_page/2021/6_Large_dp3ohd.jpg",
    "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813370/gallery_page/2021/7_Large_sgbvsj.jpg",
    "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813371/gallery_page/2021/8_Large_prqsdg.jpg",
    "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813371/gallery_page/2021/9_Large_femzxd.jpg",
    "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813371/gallery_page/2021/10_Large_zu3okl.jpg",
    "https://res.cloudinary.com/dy1pmolax/image/upload/v1688840372/gallery_page/2021/11-2021_irev6u.jpg",
    "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813372/gallery_page/2021/12_Large_xfyaqb.jpg",
    "https://res.cloudinary.com/dy1pmolax/image/upload/v1688839046/gallery_page/2021/13-2021_fx23ol.jpg",
  ];
  
  export default p2021;
  