import React from "react";
import Main from "./Components/main";

const Community = () => {
  return (
      <Main></Main>
  );
};

export default Community;
