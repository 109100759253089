const p2019 = [
    'https://res.cloudinary.com/dy1pmolax/image/upload/v1688814164/gallery_page/2019/13_2019_tmsxf0.jpg',
    'https://res.cloudinary.com/dy1pmolax/image/upload/v1688821996/gallery_page/2019/2_2019_Large_cffjis.jpg',
    'https://res.cloudinary.com/dy1pmolax/image/upload/v1688814165/gallery_page/2019/7_2019_tvp2kp.jpg',
    'https://res.cloudinary.com/dy1pmolax/image/upload/v1688814164/gallery_page/2019/4_2019_jkxh9e.jpg',
    'https://res.cloudinary.com/dy1pmolax/image/upload/v1688814166/gallery_page/2019/2_2019_tcxpdt.jpg',
    'https://res.cloudinary.com/dy1pmolax/image/upload/v1688814164/gallery_page/2019/10_2019_nlk1hd.jpg',
    'https://res.cloudinary.com/dy1pmolax/image/upload/v1688814164/gallery_page/2019/13_2019_tmsxf0.jpg',
    'https://res.cloudinary.com/dy1pmolax/image/upload/v1688814164/gallery_page/2019/4_2019_jkxh9e.jpg',
    'https://res.cloudinary.com/dy1pmolax/image/upload/v1688814164/gallery_page/2019/9_2019_glgpph.jpg',
    'https://res.cloudinary.com/dy1pmolax/image/upload/v1688823523/gallery_page/2022/11_2022_lomobl.jpg',
    'https://res.cloudinary.com/dy1pmolax/image/upload/v1688814165/gallery_page/2019/7_2019_tvp2kp.jpg',
    'https://res.cloudinary.com/dy1pmolax/image/upload/v1688814166/gallery_page/2019/2_2019_tcxpdt.jpg',
    'https://res.cloudinary.com/dy1pmolax/image/upload/v1688814164/gallery_page/2019/13_2019_tmsxf0.jpg',
];

export default p2019;