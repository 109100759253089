const i2022 = [
  "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813786/gallery_page/2022/1_2022_Large_y3p0w8.jpg",
  "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813785/gallery_page/2022/2_2022_Large_qfgsge.jpg",
  "https://res.cloudinary.com/dy1pmolax/image/upload/v1688824892/gallery_page/2022/3_Large_jbqtp2.jpg",
  "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813785/gallery_page/2022/4_2022_Large_alaq4f.jpg",
  "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813785/gallery_page/2022/5_2022_Large_pf5m9y.jpg",
  "https://res.cloudinary.com/dy1pmolax/image/upload/v1688816459/gallery_page/2022/06_2022_Large_eq6ehn.jpg",
  "https://res.cloudinary.com/dy1pmolax/image/upload/v1688824564/gallery_page/2022/7_Large_yhwf6u.jpg",
  "https://res.cloudinary.com/dy1pmolax/image/upload/v1688824564/gallery_page/2022/8_Large_jmcoep.jpg",
  "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813788/gallery_page/2022/9_2022_Large_b8c0by.jpg",
  "https://res.cloudinary.com/dy1pmolax/image/upload/v1688813787/gallery_page/2022/10_2022_Large_qn7khe.jpg",
  'https://res.cloudinary.com/dy1pmolax/image/upload/v1688823903/gallery_page/2022/11_vzpi0l.jpg', 
  'https://res.cloudinary.com/dy1pmolax/image/upload/v1688813787/gallery_page/2022/12_2022_Large_bayvv7.jpg'
  ,'https://res.cloudinary.com/dy1pmolax/image/upload/v1688813788/gallery_page/2022/11_2022_Large_rn8mbn.jpg'
];

export default i2022;
